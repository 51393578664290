import UAParser from 'ua-parser-js';

var parser = new UAParser();
var browser = parser.getBrowser();
var result = parser.getResult();
var osName = result.os.name;


var unsupportedBrowsers = [
    { name: 'Mobile Safari', version: '14' },
    { name: 'Safari', version: '14' },
    { name: 'Chrome', version: '80' },
    { name: 'Firefox', version: '80' },
    { name: 'Edge', version: '80' },
];


var isUnsupported = unsupportedBrowsers.some((unsupportedBrowser) => {
    if (browser.name === unsupportedBrowser.name) {
        if (unsupportedBrowser.version) {
            return parseFloat(browser.version) < parseFloat(unsupportedBrowser.version);
        }
        return true;
    }
    return false;
});

document.addEventListener('DOMContentLoaded', function() {
    function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
    }

    function configureMessage() {
        const messageDiv = document.createElement('div');
        messageDiv.textContent = 'Your browser or device is not supported. Please use a more recent version.';
        document.body.style.justifyContent = 'center';
        messageDiv.style.backgroundColor = '#ea9898';
        messageDiv.style.padding = '20px';
        messageDiv.style.margin = '10px';
        messageDiv.style.position= 'absolute';
        messageDiv.style.border = '1px solid #000';
        messageDiv.style.top = '10px';
        messageDiv.style.borderRadius = '5px';

        return messageDiv;
    }

    let ver = iOSversion();
    if (isUnsupported) {
        document.body.appendChild(configureMessage());
    }else if(osName == 'iOS'){
        if(ver.hasOwnProperty(0)){
            if(ver[0] < 14) {
                document.body.appendChild(configureMessage());
            }
        }
    }
});




